import React from "react";

import Loader from "../components/Loader";

function Index() {
  return <Loader />;
}

Index.hideSidebar = true;

export default Index;
